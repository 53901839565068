<template>
    <div class="layout-content-section">
        <div class="form-box-outer">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-12">
                    <div class="custom-form-group">
                        <label class="form-label">Mobile Number<span class="text-danger">*</span></label>
                        <div class="input-group custom-input-group">
                            <div class="col-lg-4 col-md-4 col-12">
                                <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                    label="label" placeholder="Select" disabled
                                    class="multiselect-custom custom-title-multiselect" :canClear="false"
                                    :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                    :canDeselect="false" />
                            </div>
                            <div class="col-lg-8 col-md-8 col-12">
                                <input type="text" :disabled="disabledinput" v-model="searchmobile" class="form-control"
                                    id="formSearchMobileNoInput" placeholder="Enter mobile number" maxlength="10"
                                    @keypress="onlyNumberMobile" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-12" v-if="!sendotpbtnhide">
                    <div class="custom-form-group" style="margin-top: 26px;">
                        <label class="form-label"></label>
                        <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                            @click="checkMobileinSystem(searchmobile)"
                            :disabled="searchmobile.length != 10 || btnloader">
                            <span v-if="!btnloader">Search</span>
                            <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="btnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="mobileotpsentflag">
                    <div class="custom-form-group">
                        <label class="form-label">Enter OTP<span class="text-danger">*</span></label>
                        <input type="text" v-model="mobileotp" class="form-control otp-input-space"
                            id="formaadharcardinput" placeholder="Enter OTP" autocomplete="off" maxlength="6"
                            minlength="3" @keypress="onlyNumber" :disabled="disableotpinput" />
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="custom-error" v-if="mobileotperr">
                                {{ mobileotperr }}
                            </span>
                            <span v-if="timerMobileStatus" class="otp-counter-text mt-1">{{
                                    timerMobileInterval
                                }}</span>
                            <button @click="resendMobileOtp(searchmobile)" class="btn btn-link aadhar-resent-otp-btn"
                                v-if="resentotpbtnstatus" :style="{ width: '80px' }" :disabled="showresentloader">
                                <span v-if="!showresentloader">Resend OTP</span>
                                <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                    v-if="showresentloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-12" v-if="mobileotpsentflag">
                    <div class="custom-form-group" style="margin-top: 26px;">
                        <button type="button" class="header-btn-outer btn btn-success" @click="verifyMobileOtp()"
                            :style="{ width: '80px' }" :disabled="mobileotp.length < 3 || showverifyloader">
                            <span v-if="!showverifyloader">Verify</span>
                            <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="showverifyloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-12 text-end" v-if="clearbtnflag">
                    <div class="custom-form-group" style="margin-top: 26px;">
                        <button type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear
                            All
                        </button>
                    </div>
                </div>
            </div>
            <div class="search-tab-outer-scroll" :style="{ height: '339px' }" v-if="mobiledetailshowflag">
                <div class="voter-exit-box-outer mb-3">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Full Name</label>
                                        <div class="from-label-value text-capitalize">
                                            {{ votergovdata.full_name ? votergovdata.full_name : "N/A" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Mobile Number</label>
                                        <div class="from-label-value" v-if="votergovdata.mobile_number">
                                            +91-{{ votergovdata.mobile_number }}
                                        </div>
                                        <div class="from-label-value" v-else>
                                            N/A
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Operator</label>
                                        <div class="from-label-value text-capitalize">
                                            {{ votergovdata.operator ? votergovdata.operator : "N/A" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Billing Type</label>
                                        <div class="from-label-value text-capitalize">
                                            {{ votergovdata.billing_type ? votergovdata.billing_type : "N/A" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider type="dashed" class="mt-0 mb-2" />
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Date of Birth</label>
                                        <div class="from-label-value text-capitalize">
                                            {{ format_date(votergovdata.date_of_birth) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-if="votergovdata.alternate_phone">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Alternate Mobile Number</label>
                                        <div class="from-label-value">
                                            {{ votergovdata.alternate_phone ? votergovdata.alternate_phone : "N/A"
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Email Id</label>
                                        <div class="from-label-value">
                                            {{ votergovdata.email ? votergovdata.email : "N/A" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider type="dashed" class="mt-0 mb-2" />
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                                <label class="form-label">Full Address</label>
                                <div class="from-label-value text-capitalize">
                                    {{ votergovdata.address ? votergovdata.address : "N/A" }}
                                </div>
                            </div>
                        </div>
                        <Divider type="dashed" class="mt-0 mb-2" />
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">City</label>
                                        <div class="from-label-value text-capitalize">
                                            {{ votergovdata.city ? votergovdata.city : "N/A" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">State</label>
                                        <div class="from-label-value text-capitalize">
                                            {{ votergovdata.state ? votergovdata.state : "N/A" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="customer-label-group mb-2">
                                        <label class="form-label">Pincode</label>
                                        <div class="from-label-value text-capitalize">
                                            {{ votergovdata.pincode ? votergovdata.pincode : "N/A" }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                <div>No records found.</div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            searchmobile: "",
            btnloader: false,
            disabledinput: false,
            mobileotp: '',
            mobileotperr: '',
            timerMobileStatus: false,
            timerMobileCount: 120,
            timerMobileInterval: null,
            resentotpbtnstatus: false,
            mobileotpsentflag: false,
            showresentloader: false,
            showverifyloader: false,
            sendotpbtnhide: false,
            disableotpinput: false,
            clientid: '',
            votergovdata: '',
            mobiledetailshowflag: false,
            nodatafoundflag: false,
            clearbtnflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        checkMobileinSystem(e) {
            this.disabledinput = true;
            this.btnloader = true;
            this.ApiService.checkmobilenumberandgetdetails({ mobile_number: e }).then((data) => {
                if (data.status === true) {
                    this.btnloader = false;
                    this.votergovdata = data.data;
                    this.mobiledetailshowflag = true;
                    this.sendotpbtnhide = true;
                    this.clearbtnflag = true;
                } else {
                    this.btnloader = false;
                    this.votergovdata = '';
                    this.sendOtp(this.searchmobile);
                    this.clearbtnflag = true;
                }
            });

        },
        sendOtp(e) {
            this.disabledinput = true;
            this.btnloader = true;
            this.ApiService.generateOTPforgetmobiledetails({ mobile_number: e }).then((data) => {
                if (data.status === true) {
                    this.clientid = data.client_id;
                    this.btnloader = false;
                    this.sendotpbtnhide = true;
                    this.mobileotpsentflag = true;
                    this.startMobileTimer();
                    this.timerMobileStatus = true;
                } else {
                    this.mobileotpsentflag = false;
                    this.btnloader = false;
                }
            });
        },

        resendMobileOtp(e) {
            this.showresentloader = true;
            this.ApiService.generateOTPforgetmobiledetails({ mobile_number: e }).then((data) => {
                if (data.status === true) {
                    this.clientid = data.client_id;
                    this.showresentloader = false;
                    this.disableotpinput = false;
                    this.startMobileTimer();
                    this.timerMobileStatus = true;
                } else {
                    this.mobileotp = '';
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 120;
                    this.showresentloader = false;
                }
            });

        },
        verifyMobileOtp() {
            this.showverifyloader = true;
            this.disableotpinput = true;
            let fields = {};
            fields["client_id"] = this.clientid;
            fields["OTP"] = this.mobileotp;
            this.ApiService.getmobiledetails(fields).then((data) => {
                if (data.status === true) {
                    this.votergovdata = data.data;
                    this.mobiledetailshowflag = true;
                    this.showverifyloader = false;
                    this.mobileotpsentflag = false;
                } else {
                    this.mobiledetailshowflag = false;
                    this.votergovdata = '';
                    this.mobileotp = '';
                    this.mobileotpsentflag = false;
                    this.timerMobileStatus = false;
                    this.timerMobileCount = 120;
                    this.showverifyloader = false;
                    this.nodatafoundflag = true;
                }
            });
        },

        startMobileTimer() {
            this.resentotpbtnstatus = false;
            this.timerMobileStatus = true;
            if (this.timerMobileCount > 0) {
                setTimeout(() => {
                    this.timerMobileCount--;
                    this.startMobileTimer();
                }, 1000);
                const minutes = Math.floor(this.timerMobileCount / 60);
                let seconds = this.timerMobileCount % 60;
                if (seconds < 10) {
                    this.timerMobileInterval = `0${minutes}:0${seconds}`;
                } else {
                    this.timerMobileInterval = `0${minutes}:${seconds}`;
                }
            } else {
                this.timerMobileCount = 120;
                this.timerMobileStatus = false;
                this.resentotpbtnstatus = true;
                this.mobileotp = '';
                this.disableotpinput = true;
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formSearchMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        
        searchvoterclearbtn() {
            this.searchmobile = '';
            this.btnloader = false;
            this.disabledinput = false;
            this.mobileotp = '';
            this.mobileotperr = '';
            this.timerMobileStatus = false;
            this.timerMobileCount = 120;
            this.timerMobileInterval = null;
            this.resentotpbtnstatus = false;
            this.mobileotpsentflag = false;
            this.showresentloader = false;
            this.showverifyloader = false;
            this.sendotpbtnhide = false;
            this.disableotpinput = false;
            this.clientid = '';
            this.votergovdata = '';
            this.mobiledetailshowflag = false;
            this.nodatafoundflag = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.aadhar-resent-otp-btn {
    letter-spacing: 0px;
    color: #3362db;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
    padding: 0;
    text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
    outline: 0;
}

.aadhar-resent-otp-btn:focus {
    box-shadow: none;
}

.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
</style>