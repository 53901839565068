<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Mobile Related Records</div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="mobile-info-details-tab" data-bs-toggle="tab"
                data-bs-target="#mobilefetchdetail" type="button" role="tab" aria-controls="vehicle-details"
                aria-selected="true" @click="selectedtab((e = 1))">
                Mobile Reg Records
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="pan-info-tab" data-bs-toggle="tab" data-bs-target="#panfetchdetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="selectedtab((e = 2))">
                PAN Details
            </button>
        </li>
        <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="name-info-tab" data-bs-toggle="tab" data-bs-target="#namefetchdetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="selectedtab((e = 3))">
                Name Info
            </button>
        </li> -->
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="upi-info-tab" data-bs-toggle="tab" data-bs-target="#upifetchdetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="selectedtab((e = 4))">
                UPI Details
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="uan-info-tab" data-bs-toggle="tab" data-bs-target="#uanfetchdetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="selectedtab((e = 5))">
                UAN Details
            </button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="mobilefetchdetail" role="tabpanel"
            aria-labelledby="mobile-info-details-tab">
            <div class="search-voter-section-outer" style="height: calc(100vh - 180px);">
                <div v-if="selected_tab == 1"><MobileInfo /></div>
            </div>
        </div>
        <div class="tab-pane fade" id="panfetchdetail" role="tabpanel" aria-labelledby="pan-info-tab">
            <div class="search-voter-section-outer" style="height: calc(100vh - 180px);">
                <div v-if="selected_tab == 2"><PANInfo /></div>
            </div>
        </div>
        <div class="tab-pane fade" id="namefetchdetail" role="tabpanel" aria-labelledby="name-info-tab">
            <div class="search-voter-section-outer" style="height: calc(100vh - 180px);">
                <div v-if="selected_tab == 3"><NameInfo /></div>
            </div>
        </div>
        <div class="tab-pane fade" id="upifetchdetail" role="tabpanel" aria-labelledby="upi-info-tab">
            <div class="search-voter-section-outer" style="height: calc(100vh - 180px);">
                <div v-if="selected_tab == 4"><UPIInfo /></div>
            </div>
        </div>
        <div class="tab-pane fade" id="uanfetchdetail" role="tabpanel" aria-labelledby="uan-info-tab">
            <div class="search-voter-section-outer" style="height: calc(100vh - 180px);">
                <div v-if="selected_tab == 5"><UANInfo /></div>
            </div>
        </div>
    </div>
</template>
<script>
import MobileInfoDetails from "./MobileInfoDetails.vue";
import PanInfoDetails from "./PanInfoDetails.vue";
import NameInfoDetails from "./NameInfoDetails.vue";
import UpiInfoDetails from "./UpiInfoDetails.vue";
import UanInfoDetails from "./UanInfoDetails.vue";
export default {
    components: {
        MobileInfo: MobileInfoDetails,
        PANInfo: PanInfoDetails,
        NameInfo: NameInfoDetails,
        UPIInfo: UpiInfoDetails,
        UANInfo: UanInfoDetails,
    },
    data() {
        return {
            selected_tab: 1,
        };
    },
    methods: {
        selectedtab(e) {
            this.selected_tab = e;
        },
    },
};
</script>
<style scoped>
.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
    padding: 15px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
</style>